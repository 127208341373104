import { Component, OnInit } from '@angular/core';
import { ShapeComponent } from '../shape/shape.component';
import { ShapeType } from '../../model/shape-types';
import { MousePosition, Rectangle } from '../../model/shape';

@Component({
    selector: 'app-rectangle',
    templateUrl: './rectangle.component.html',
    styleUrls: ['./rectangle.component.css']
})
export class RectangleComponent extends ShapeComponent implements OnInit {

    formFields: any = [
        {
            name: 'x',
            label: 'X:',
            type: 'input',
            inputType: 'text',
            value: ''
        },
        {
            name: 'y',
            label: 'Y:',
            type: 'input',
            inputType: 'text',
            value: ''
        },
    ];

    constructor() {
        super();
        //console.log('RectangleComponent constructor');
        this.shape = new Rectangle();
        this.shapeType = ShapeType.Rectangle;
    }

    ngOnInit() {
        //console.log('RectangleComponent ngOnInit');
    }

    setStyles() {
        let styles = {
            'stroke': this.shape.shapeProperties.strokeColor,
            'fill': this.shape.shapeProperties.fillColor,
            'stroke-width': this.shape.shapeProperties.strokeWidth
        };
        return styles;
    }

    startDrawing(beginPosition: any): void {
        //console.log('RectanleComponent startDrawing at ', beginPosition);
        if (this.shape instanceof Rectangle) {
            this.shape.originX = beginPosition.startX;
            this.shape.originY = beginPosition.startY;
        }
    }

    draw(currentPosition: any): void {
        //console.log('RectangleComponent draw', this.shape);
        if (this.shape instanceof Rectangle) {
            //if (Math.abs(currentPosition.x - this.shape.originX) > 15) {
                this.shape.width = Math.abs(currentPosition.x - currentPosition.startX) - 2;
                this.shape.height = Math.abs(currentPosition.y - currentPosition.startY) - 2;
                this.shape.originX = (currentPosition.x - currentPosition.startX < 0) ? (currentPosition.x + 2) : currentPosition.startX;
                this.shape.originY = (currentPosition.y - currentPosition.startY < 0) ? (currentPosition.y + 2) : currentPosition.startY;
            
        }
    }

}
