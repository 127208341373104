﻿import { Component, Inject, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { Project } from '../_models';
import { UserService } from '../_services';
import { Globals } from '../globals';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


@Component({
    templateUrl: 'home.component.html',
    styleUrls: ['./dialog.remove.css']
})
export class HomeComponent implements OnInit {
    projects: Project[] = [];
    Globals: Globals;
    loadProject: boolean = true;
    timeout: any = null;

    constructor(private userService: UserService,
        Globals: Globals,
        public dialog: MatDialog) {
        this.Globals = Globals;
        this.Globals.topBar = true;
        if (localStorage.getItem('thisUser')) this.Globals.thisUser = JSON.parse(localStorage.getItem('thisUser'));
    }

    ngOnInit() {
        this.userService.getAll().pipe(first()).subscribe(projects => {
            this.projects = projects;
            this.Globals.updateProjects(projects);
            this.loadProject = false;
        });
    }

    editText(event, type, project: Project) {
        const element = document.getElementById(type + project.id) as HTMLInputElement;
        element.readOnly = false;
        element.focus();
        document.getElementById(type + project.id).classList.add("inputChange");
    }

    showEdit(event, type, project: Project) {
        const element = document.getElementById('edit-' + type + '-' + project.id) as HTMLInputElement;
        element.style.display = "initial";
    }

    hideEdit(event, type, project: Project) {
        const element = document.getElementById('edit-' + type + '-' + project.id) as HTMLInputElement;
        element.style.display = "none";
    }

    saveEditText(event, type, project: Project) {
        var $this = this;
        const element = document.getElementById('name' + project.id) as HTMLInputElement;
        const element2 = document.getElementById('description' + project.id) as HTMLInputElement;

        element.readOnly = true;
        element.classList.remove("inputChange");
        element2.readOnly = true;
        element2.classList.remove("inputChange");

        if (type == "name") {
            project.name = event.target.value;
        } else {
            project.description = event.target.value;
        }
        $this.saveEditService(project);

        return;
    }

    saveEditService(project: Project) {
        const formData = new FormData();
        formData.append('project', JSON.stringify(project));


        this.userService.editInfos(formData).pipe(first())
            .subscribe(
                data => {

                },
                error => {
                    console.log(error);
                });
    }

    onMouseOutTexte(event) {
        var inputarray = document.getElementsByClassName("project-text");
        for (var i = 0; i < inputarray.length; i++) {
            const element = inputarray[i] as HTMLInputElement;
            element.classList.remove("inputChange");
            element.readOnly = true;
        }
        //console.log(event);
    }

    editProject(project) {
        let url = "/editor/" + project.id
        window.open(url, "_blank");

        //event.preventDefault();
    }

    deleteProject(project) {

        const dialogRefRemove = this.dialog.open(DialogRemoveProject, {
            width: '350px',
            data: { project: project }
        });

        return false;

    }

    viewProject(project) {

        let url = "/preview/" + project.id
        window.open(url, "_blank");

        event.preventDefault();
    }
}

export interface DialogDataRemove {
    project: Project;
}

@Component({
    selector: 'dialog-remove',
    templateUrl: './dialog-remove-project.html'
})

export class DialogRemoveProject implements OnInit {

    Globals: Globals;
    loadRemoveProject: boolean = false;

    constructor(private userService: UserService,
        public dialogRefRemove: MatDialogRef<DialogRemoveProject>,
        @Inject(MAT_DIALOG_DATA) public data: DialogDataRemove,
        Globals: Globals) {
        this.Globals = Globals;
    }

    ngOnInit() {
    }

    removeProject() {
        this.loadRemoveProject = true;
        this.userService.deleteProject(this.data.project).pipe(first())
            .subscribe(
                data => {
                    if (data.success) {
                        this.Globals.deleteProject(this.data.project);
                        this.loadRemoveProject = false;
                        this.dialogRefRemove.close();
                    }
                },
                error => {
                    console.log(error);
                });

        return false
    }

    close() {
        this.dialogRefRemove.close();
    }
}