﻿import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, first } from 'rxjs/operators';
import { Project } from '../_models';
import { Globals } from '../globals';


@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient, private Globals: Globals) { }

    getAll() {
        return this.http.get<any>(this.Globals.urlBackend + 'api/projects');
    }

    deleteProject(project: Project) {
        return this.http.post<any>(this.Globals.urlBackend + 'api/delete/project', project)
            .pipe(map(status => {
                return status;
            }));
    }

    saveProject(formData) {

        return this.http.post<any>(this.Globals.urlBackend + 'api/project', formData)
            .pipe(map(project => {
                return project;
            }));
    }

    editPermission(formData) {

        return this.http.post<any>(this.Globals.urlBackend + 'api/project', formData)
            .pipe(map(project => {
                return project;
            }));
    }

    editInfos(formData) {
        return this.http.post<any>(this.Globals.urlBackend + 'api/edit/project', formData)
            .pipe(map(project => {
                return project;
            }));
    }

    getFile(project: Project) {
        return this.http.post<any>(this.Globals.urlBackend + 'file', project)
            .pipe(map(file => {
                let blob = new Blob([file], { type: 'application/zip' });
                return blob;
            }));
    }

    getCurrentUser(){
        let postData = {
            currentUser : localStorage.getItem('currentUser')
        }
        return this.http.post<any>(this.Globals.urlBackend + 'api/userInfos', postData)
            .pipe(map(user => {
                return user;
            }));
    }

    editCurrentUser(username: string, password: string, email: string) {

        let postData = {
            currentUser : localStorage.getItem('currentUser'),
            "username": username,
            "email": email,
            "password": password
        }

        return this.http.post<any>(this.Globals.urlBackend + 'api/edit/userInfos', postData)
            .pipe(map(user => {
                if (user) {
                    user.authdata = user.access_token;
                    localStorage.setItem('currentUser', JSON.stringify(user));
                }

                return user;
            }));

    }

    getProjectPreview(id) {
        let postData = {
            id : id,
            currentUser : localStorage.getItem('currentUser')
        }
        return this.http.post<any>(this.Globals.urlBackend + 'preview', postData)
            .pipe(map(result => {
                return result;
            }));
    }
}