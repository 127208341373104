import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Globals } from '../globals';
import { UserService } from '../_services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '../_models';

@Component({
    selector: 'app-root',
    templateUrl: 'account.component.html'
})
export class AccountComponent implements OnInit {
    Globals: Globals;
    loginForm: FormGroup;
    loading = false;
    loadProject = true;
    submitted = false;
    user: User;

    constructor(private userService: UserService,
        Globals: Globals,
        private formBuilder: FormBuilder) {
        this.Globals = Globals;
        this.Globals.topBar = true;
        if(localStorage.getItem('thisUser')) this.Globals.thisUser = JSON.parse(localStorage.getItem('thisUser'));
    }

    ngOnInit() {

        this.userService.getCurrentUser().pipe(first()).subscribe(user => {
            this.user = user;
            this.loadProject = false;
            this.loginForm = this.formBuilder.group({
                username: [user.username, Validators.required],
                password: ['', Validators.required],
                email: [user.email, Validators.required]
            });
        });

    }

    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.userService.editCurrentUser(this.f.username.value, this.f.password.value, this.f.email.value)
            .pipe(first())
            .subscribe(
                data => {
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                });
    }


}