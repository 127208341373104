﻿import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '../_services';
import { Globals } from '../globals';

@Component({ templateUrl: 'register.component.html' })
export class RegisterComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    Globals: Globals;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        Globals: Globals) {
        this.Globals = Globals;
        this.Globals.topBar = false;
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            email: ['', Validators.required]
        });

        // reset login status
        this.authenticationService.logout();

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.register(this.f.username.value, this.f.password.value, this.f.email.value)
            .pipe(first())
            .subscribe(
                data => {
                    this.authenticationService.login(this.f.username.value, this.f.password.value)
                        .pipe(first())
                        .subscribe(
                            data => {
                                this.router.navigate([this.returnUrl]);
                            },
                            error => {
                                this.error = error;
                                this.loading = false;
                            });
                },
                error => {
                    this.error = error;
                    this.loading = false;
                });
    }
}
