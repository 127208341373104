import { Component, Inject } from '@angular/core';
import { Globals } from './globals';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Project } from './_models';
import { UserService } from './_services';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ng-icos';
  Globals: Globals;

  constructor(Globals: Globals, public dialog: MatDialog) {
    this.Globals = Globals;
  }


  openDialog() {
    const dialogRef = this.dialog.open(DialogAddProject, {
      width: '350px',
      data: {}
    });

    return false;
  }

}
export interface DialogData {
  name: string;
  description: string;
}

@Component({
  selector: 'dialog-add',
  templateUrl: './home/dialog-add-project.html'
})

export class DialogAddProject {


  private name: string;
  private description: string;
  private id: string;
  private newProject: Project;
  Globals: Globals;
  loadAddProject: boolean = false;

  constructor(private userService: UserService,
    public dialogRef: MatDialogRef<DialogAddProject>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    Globals: Globals) {
    this.Globals = Globals;
  }

  

  addProject() {
    this.loadAddProject = true;
    let name = this.data.name;
    let description = this.data.description;
    let idP = this.Globals.guid();

    this.newProject = {
      id: 0,
      name: name,
      description: description,
      media: "",
      link: "",
      status: "new",
      data: "",
      params: "",
      permission: ""
    };

    const formData = new FormData();
    formData.append('file', null);
    formData.append('project', JSON.stringify(this.newProject));
    formData.append('action', 'saveProject');

    this.userService.saveProject(formData).pipe(first())
      .subscribe(
        data => {
          this.Globals.addProject(data);
          let url = "/editor/" + data.id;
          window.open(url, "_blank");
          this.loadAddProject = false;
          this.dialogRef.close();
        },
        error => {
          console.log(error);
        });
  }

}
