﻿import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Globals } from '../globals';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    constructor(private http: HttpClient, private Globals: Globals) { }

    login(username: string, password: string) {

        return this.http.post<any>(this.Globals.urlBackend + 'oauth/v2/token', {
            "client_id": this.Globals.client_id,
            "client_secret": this.Globals.client_secret,
            "grant_type": this.Globals.grant_type,
            "username": username,
            "password": password
        })
            .pipe(map(user => {

                if (user) {
                    // store user details and basic auth credentials in local storage 
                    // to keep user logged in between page refreshes
                    user.authdata = user.access_token;
                    localStorage.setItem('currentUser', JSON.stringify(user));
                }

                //let body = user;
                //return of(new HttpResponse({ status: 200, body }));
            }));
    }

    register(username: string, password: string, email: string) {

        return this.http.post<any>(this.Globals.urlBackend + 'createUser', {
            "username": username,
            "email": email,
            "password": password
        })
            .pipe(map(user => {
                // login successful if there's a user in the response
                if (user) {
                    // store user details and basic auth credentials in local storage 
                    // to keep user logged in between page refreshes
                    user.authdata = user.access_token;
                    localStorage.setItem('currentUser', JSON.stringify(user));
                }

                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('thisUser');
    }
}