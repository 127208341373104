import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Project, User } from './_models';
import { Location } from '@angular/common';

export interface Graph {
    nodes: Array<Node>;
}

export interface Node {
    id: string;
    name: string;
    image: string;
    home: boolean;
    edges: Array<Edge>;
}


export interface Edge {
    coords: Coord;
    id: string;
    properties: Array<Propertie>;
    source: string;
    target: string;
    shapeType: any;
    shape: any;
}
export interface Coord {
    x: number;
    y: number;
}

export interface Propertie {
    ID: string;
    name: string;
    value: string;
}

export interface CurrentDraw {
    id: string;
    image: string;
    name: string;
    edges: Array<Edge>;
}

export interface Params {
    borderStyle: string;
    borderWidth: number;
    borderColor: string;
    tooltipBgColor: string;
    tooltipTextColor: string;
    tooltipLinkColor: string;
    showEdge: boolean;
}

@Injectable()
export class Globals {
    urlBackend: string = "https://icos.know-of.com/backend/";
    client_id: string = "2_2h8tb1pgcdeso88kg0w00sck4o4ko4wss488g0o4kc0gowscgk";
    client_secret: string = "64qgigm9ctc0sw8kc4oc0c8s4okcwgswos404og0cs0sk40kc0";
    grant_type: string = "password";
    thisUser: User;
    isAdmin: boolean = false;
    topBar: boolean = false;
    projects: Project[] = [];
    modeView: boolean = false;
    currentDrawenNode: CurrentDraw;
    defaultImg: string = "assets/asset.png";
    imgURL: string = this.defaultImg;
    imgURL$ = new BehaviorSubject(this.imgURL);
    historyLocal: Array<string> = [];
    currentIdInHistory: number = null;
    graph: Graph = {
        nodes: [
        ]
    };
    params: Params = {
        borderStyle: "solid",
        borderWidth: 3,
        borderColor: "#0000ff80",
        tooltipBgColor: "#fff",
        tooltipTextColor: "#333",
        tooltipLinkColor: "#337ab7",
        showEdge: true
    };
    images: Array<string> = [];

    constructor(private location: Location) { }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
    };

    graphPrototype() {
        return {
            nodes: [
            ]
        }
    };

    switchMode = function () {
        this.isAdmin = !this.isAdmin;
        //init(); 

        //$( "#modeSwitcher" ).text(!isAdmin ? "Passer en mode edition" : "Passer en mode vue");	
        return false;
    }

    createMap() {
        this.images = [];
        this.graph = this.graphPrototype();
        this.isAdmin = false;
        //switchMode();
        //$( '#anDialog' ).dialog('open');
        return false;
    };

    loadAndDisplayImage(idNode, imageName, idFileInput) {
        //let files = evt.target.files; // FileList object
        //if (idFileInput == null) idFileInput = this.imageNode;
        let f = idFileInput[0];
        let me = this;
        // Loop through the FileList and render image files as thumbnails.
        //for (let i = 0, f; f = files[i]; i++) {

        // Only process image files.
        if (!f.type.match('image.*')) {
            alert("Le fichier n est pas une image.");
            return;
        }

        let reader = new FileReader();

        // Closure to capture the file information.
        reader.onload = (function (theFile) {
            return function (e) {
                // Render thumbnail.
                //          let span = document.createElement('span');
                //          span.innerHTML = ['<img class="thumb" src="', e.target.result,
                //                            '" title="', escape(theFile.name), '"/>'].join('');
                //          document.getElementById('list').insertBefore(span, null);

                //let myName = guid();//theFile.name; //
                //images.push({""+myName+"" : e.target.result});

                me.images[imageName] = e.target.result;
                //console.log(idNode,imageName);


                //this.draw(idNode);

            };
        })(f);

        // Read in the image file as a data URL.
        reader.readAsDataURL(f);
        //}
    };

    getHomePage() {
        for (let node in this.graph.nodes) {
            if(this.graph.nodes[node].home != undefined && this.graph.nodes[node].home){
                return this.graph.nodes[node];
            }
        }
        return null;
    };

    makeItHome(Node) {
        for (let node in this.graph.nodes) {
            this.graph.nodes[node].home = false;
        }
        let index = this.graph.nodes.indexOf(Node);
        this.graph.nodes[index].home = true;
        return null;
    };

    removeNode(Node) {
        let index = this.graph.nodes.indexOf(Node);
        this.graph.nodes.splice(index, 1);
        return null;
    };

    getNodeFromId(idNode) {

        for (let node in this.graph.nodes) {
            // 		console.debug(graph.nodes[node]);
            if (this.graph.nodes[node].id == idNode) {
                return this.graph.nodes[node];
            }
        }
        return null;
    };

    getEdgeFromId(id) {
        for (let edge in this.currentDrawenNode.edges) {
            // 		console.debug(graph.nodes[node]);
            if (this.currentDrawenNode.edges[edge].id == id) {
                return this.currentDrawenNode.edges[edge];
            }
        }
        return null;
    };

    removeEdge(Edge) {
        let index = this.currentDrawenNode.edges.indexOf(Edge);
        this.currentDrawenNode.edges.splice(index, 1);
        return null;
    };

    getNodesFromGraph() {
        let newNodesArray = [];

        for (let node in this.graph.nodes) {
            newNodesArray.push({ id: this.graph.nodes[node].id, label: this.graph.nodes[node].name });
        }

        return newNodesArray;
    };

    getEdgesFromGraph() {
        let newEdgesArray = [];
        let addedEdgesArray = [];
        for (let node in this.graph.nodes) {
            for (let edge in this.graph.nodes[node].edges) {
                let oneWayEdge = this.graph.nodes[node].edges[edge].source + "<->" + this.graph.nodes[node].edges[edge].target;
                let secondWayEdge = this.graph.nodes[node].edges[edge].target + "<->" + this.graph.nodes[node].edges[edge].source;

                //console.debug("addedEdgesArray");
                //console.debug(addedEdgesArray);
                //console.debug("oneWayEdge");
                //console.debug(oneWayEdge);
                //console.debug("inArray");
                //console.debug($.inArray(oneWayEdge, addedEdgesArray));

                if (addedEdgesArray.indexOf(oneWayEdge) < 0) {
                    newEdgesArray.push({ from: this.graph.nodes[node].edges[edge].source, to: this.graph.nodes[node].edges[edge].target });
                    addedEdgesArray.push(oneWayEdge);
                    addedEdgesArray.push(secondWayEdge);
                }
            }
        }

        return newEdgesArray;
    };

    addNodeToHistory() {

        if (this.historyLocal[this.historyLocal.length - 1] == this.currentDrawenNode.id) {
            return;
        }

        if (this.currentIdInHistory == null) {
            this.currentIdInHistory = 0;
        } else {
            this.currentIdInHistory = this.currentIdInHistory + 1;
        }

        if (this.currentIdInHistory < this.historyLocal.length) {
            this.historyLocal = this.historyLocal.slice(0, this.currentIdInHistory);
        }
        this.historyLocal[this.currentIdInHistory] = this.currentDrawenNode.id;

    };

    getListNodesWithoutCurrent() {
        const nodesToReturn = Object.assign([], this.graph.nodes);
        const index: number = nodesToReturn.indexOf(this.currentDrawenNode);
        if (index !== -1) {
            nodesToReturn.splice(index, 1);
        }
        return nodesToReturn;
    };

    updateProjects(projects) {
        this.projects = projects;
        localStorage.setItem('projects', JSON.stringify(projects));
    };

    deleteProject(project) {
        this.projects = JSON.parse(localStorage.getItem('projects'));
        this.projects = this.projects.filter(item => item.id != project.id);
        localStorage.setItem('projects', JSON.stringify(this.projects));
        return this.projects;
    };

    updateProject(project) { 
        this.projects = JSON.parse(localStorage.getItem('projects'));
        this.projects = this.projects.filter(item => item.id != project.id);
        this.projects.push(project);
        localStorage.setItem('projects', JSON.stringify(this.projects));

        let url = "/editor/" + project.id;
        this.location.replaceState(url);
    };

    addProject(project) { 
        this.projects.push(project);
        localStorage.setItem('projects', JSON.stringify(this.projects));
    };

    removeElement(element) {
        element && element.parentNode && element.parentNode.removeChild(element);
    }

    appendCss() {
        this.removeElement(document.getElementById('customCss'));
        let customCss = '.shapeDiv {border-width: ' + this.params.borderWidth + 'px;border-style: ' + this.params.borderStyle + ';border-color: ' + this.params.borderColor + ';} ';
        var htmlDiv = document.createElement('div');
        htmlDiv.innerHTML = '<style id="customCss">' + customCss + '</style>';
        document.getElementsByTagName('head')[0].appendChild(htmlDiv.childNodes[0]);
    }

    // appendTooltipCss(scal) {
    //     this.removeElement( document.getElementById('customTooltipCss') );
    //     let customCss = '.tooltip {transform : scale('+scal+');-moz-transform : scale('+scal+');-webkit-transform : scale('+scal+'),;-ms-transform : scale('+scal+')}';
    //     var htmlDiv = document.createElement('div');
    //     htmlDiv.innerHTML = '<style id="customTooltipCss">' + customCss + '</style>';
    //     document.getElementsByTagName('head')[0].appendChild(htmlDiv.childNodes[0]);
    // }

}