import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Globals } from './globals';

@Pipe({ name: 'changeToHTMLArray' })

export class PropertiesPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer,private Globals: Globals) { }
  transform(values: any) {
    let target = values.target;
    values = values.properties;
    let returnHtml = "";

    let content = '';
    if (values.length > 0) {
      content += "<table style='overflow: hidden;max-width: inherit;'>";
    }
    for (var i = 0, len = values.length; i < len; i++) {
      let obj = values[i];
      let value = '';
      if(obj.value) value = obj.value;
      var length = 3000;
      if (new RegExp('^' + "file://").test(value) ||
        new RegExp('^' + "http://").test(value) ||
        new RegExp('^' + "https://").test(value)) {
          // set to the number of characters you want to keep
        var trimmedPathname = value.substring(0, Math.min(length, value.length));
        if(value.length > length) trimmedPathname = trimmedPathname + '...';
        value = "<a style='color:"+this.Globals.params.tooltipLinkColor+"' title='" + value + "' href='" + value + "' target='blank'>" + trimmedPathname + "</a>";
      } else if (this.isValidEmail(value)) {
        var trimmedPathname = value.substring(0, Math.min(length, value.length));
        if(value.length > length) trimmedPathname = trimmedPathname + '...';
        value = "<a style='color:"+this.Globals.params.tooltipLinkColor+"' title='" + value + "' href='mailto:" + value + "' >" + trimmedPathname + "</a>";
      }else{
        var trimmedPathname = value.substring(0, Math.min(length, value.length));
        if(value.length > length) trimmedPathname = trimmedPathname + '...';
        value = "<span title='" + value + "' >" + trimmedPathname + "</span>";
      }
      var nameTD = (this.isBlank(obj.name)) ? "" : "<td align='left'>" + obj.name + ":</td>";
      var valueTD = (this.isBlank(obj.name)) ? "<td align='left' colspan='2'>" + value + "</td>" : "<td align='left'>" + value + "</td>";
      
      content += "<tr>" + nameTD + valueTD + "</tr>";
    }
    if(values.length > 0) content += "</table>";
    if(target != ""){
      let Node = this.Globals.getNodeFromId(target);
      content += '<hr style="border-color:'+this.Globals.params.tooltipTextColor+'"><p style="text-align:center;cursor:pointer;" title="Cliquer pour aller à la page"><i class="fas fa-arrow-circle-right"></i> ' + Node.name +'</p>';
    }
    //console.log(this._sanitizer.bypassSecurityTrustHtml(returnHtml));
    return this._sanitizer.bypassSecurityTrustHtml(content);
  }

  isValidEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  isBlank(str) {
    return (!str || /^\s*$/.test(str));
  };
}