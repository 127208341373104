import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatSliderModule, MatSelectModule, MatDialogModule } from '@angular/material';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { DialogAddProject, AppComponent } from './app.component';
import { Globals } from './globals';
import { PropertiesPipe } from './properties.pipe';
import { FilterPipe } from './filter.pipe';
import { MapComponent } from './map/map.component';
import { NodeComponent } from './node/node.component';
import { EdgeComponent } from './edge/edge.component';
import { DialogParamsComponent } from './map/map.component';
import { DialogParamsSecComponent } from './map/map.component';
import { DialogMapComponent } from './map/map.component';
import { DialogEdgeComponent } from './map/map.component';
import { DialogViewComponent } from './map/map.component';
import { DialogRemoveProject, } from './home/home.component';
import { ShapeService } from './service/shape.service';
import { CircleComponent } from './components/circle/circle.component';
import { RectangleComponent } from './components/rectangle/rectangle.component';
import { ShapeComponent } from './components/shape/shape.component';
import { Ng2PanZoomModule } from 'ng2-panzoom';
import { DragDirective } from './dragDrop.directive';
import { ColorPickerModule } from 'ngx-color-picker';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { ClickOutsideModule } from 'ng-click-outside';

import { routing } from './app.routing';

import { BasicAuthInterceptor, ErrorInterceptor } from './_helpers';
import { HomeComponent } from './home';
import { AccountComponent } from './account';
import { LoginComponent } from './login';
import { RegisterComponent } from './register';


@NgModule({
  declarations: [
    HomeComponent,
    AccountComponent,
    LoginComponent,
    RegisterComponent,
    PropertiesPipe,
    FilterPipe,
    AppComponent,
    MapComponent,
    NodeComponent,
    EdgeComponent,
    DialogParamsComponent,
    DialogParamsSecComponent,
    DialogMapComponent,
    DialogAddProject,
    DialogRemoveProject,
    DialogEdgeComponent,
    DialogViewComponent,
    CircleComponent,
    RectangleComponent,
    ShapeComponent,
    DragDirective
  ],
  imports: [
    BrowserModule,
    MatSliderModule,
    MatSelectModule,
    MatDialogModule,
    BrowserAnimationsModule,
    FormsModule,
    DragDropModule,
    Ng2PanZoomModule,
    NgbModule,
    ColorPickerModule,
    ReactiveFormsModule,
    HttpClientModule,
    routing,
    DeviceDetectorModule.forRoot(),
    ClickOutsideModule
  ],
  providers: [
    Globals, 
    ShapeService, 
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    DialogParamsComponent,
    DialogParamsSecComponent,
    DialogMapComponent,
    DialogAddProject,
    DialogRemoveProject,
    DialogEdgeComponent,
    DialogViewComponent,
    CircleComponent,
    RectangleComponent,
    ShapeComponent,
  ]
})
export class AppModule { }
