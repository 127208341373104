import { Component, ViewEncapsulation, Inject, OnInit, OnDestroy, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef, ComponentFactoryResolver, ViewContainerRef, Injector, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import * as vis from 'vis';
import { PanZoomConfig, PanZoomAPI, PanZoomModel } from 'ng2-panzoom';

import { ShapeComponent } from '../components/shape/shape.component';
import { ShapeProperties, MousePosition } from '../model/shape';
import { ShapeType } from '../model/shape-types';
import { ShapeService } from '../service/shape.service';
import { CircleComponent } from '../components/circle/circle.component';
import { RectangleComponent } from '../components/rectangle/rectangle.component';

import { DomSanitizer } from '@angular/platform-browser';

import { saveAs } from '@progress/kendo-file-saver';
import * as JSZip from 'jszip';
import * as JSZipUtils from 'jszip-utils';

import { FileHandle } from '../dragDrop.directive';
import { DeviceDetectorService } from 'ngx-device-detector';

import { ActivatedRoute } from '@angular/router';
import { Project } from '../_models';
import { UserService } from '../_services';
import { Globals } from '../globals';

export interface DialogData {
  nameNode: string;
  offsetY: number;
  offsetX: number;
  idEditedEdge: string;
  Node: any;
  shapeValue: any;
  selectedShape: any;
  project: any;
  permission: string;
}

@Component({
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
  encapsulation: ViewEncapsulation.None,
})

export class MapComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('container') container: ElementRef;
  @ViewChild('mainImg') mainImg: ElementRef;

  math = Math;
  imgURL: string;
  searchText: string;
  showSearch: boolean = true;
  searchPage: any[] = [];
  searchPoint: any[] = [];
  numPage: number = 0;
  numPoint: number = 0;
  messages: any[] = [];
  subscription: Subscription;
  Globals: Globals;
  zoomLevel: number = 2;
  changeZoomLevel: boolean = true;
  containerClosed: boolean = false;
  pannedTo: boolean = false;
  mouse: any = {
    x: 0,
    y: 0,
    startX: 0,
    startY: 0
  };

  public panZoomConfig: PanZoomConfig = new PanZoomConfig({
    zoomOnMouseWheel: false,
    zoomOnDoubleClick: false,
    zoomButtonIncrement: 0.5
  });
  private panZoomAPI: PanZoomAPI;
  private apiSubscription: Subscription;
  private modelChangedSubscription: Subscription;
  public panzoomModel: PanZoomModel;

  svg: any;
  currentPosition: MousePosition = new MousePosition();
  selectedShape: ShapeType;
  shapeValue: string;
  isDragging: boolean = false;
  isDrawing: boolean = false;
  isResizing: boolean = false;
  isSelectingPoints: boolean = false;
  selectedComponent: ShapeComponent;
  shapeProperties: ShapeProperties = new ShapeProperties();
  preview: boolean = false;
  firstOpen: boolean = true;
  loadSaveProject: boolean = false;
  files: FileHandle[] = [];
  resize: boolean = false;
  drag: boolean = false;
  startWidth: number;
  startHeight: number;
  startX: number;
  startY: number;
  selectEdge: string;
  hideTooltip: boolean = true;
  isMobile: boolean;
  isTablet: boolean;
  isDesktopDevice: boolean;
  project: Project;
  userSer: UserService;

  userFiles: string = './assets/media/maps/';


  constructor(
    private userService: UserService,
    private deviceService: DeviceDetectorService,
    private sanitizer: DomSanitizer,
    private changeDetector: ChangeDetectorRef,
    location: Location,
    public dialog: MatDialog,
    Globals: Globals,
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
    private shapeService: ShapeService,
    private route: ActivatedRoute) {
    this.Globals = Globals;
    this.userSer = userService;
    var me = this;
    this.route.params.subscribe(params => {

      if (location.path() == "/preview/" + params.id) {

        this.userService.getProjectPreview(params.id).pipe(first())
          .subscribe(
            data => {
              if (data.success) {
                me.project = data.project;
                this.userService.getFile(me.project).pipe(first())
                  .subscribe(
                    data => {
                      var zip = new JSZip();
                      zip.loadAsync(data)
                        .then(function (zip) {
                          return zip.file("data.json").async("string");
                        }).then(function success(text) {
                          me.Globals.graph = JSON.parse(text);
                          if(me.Globals.getHomePage() !== null){
                            me.Globals.currentDrawenNode = me.Globals.getHomePage();
                          }else{
                            me.Globals.currentDrawenNode = me.Globals.graph.nodes[0];
                          }
                          me.Globals.imgURL = me.Globals.currentDrawenNode.image;
                          me.Globals.imgURL$.next(me.Globals.currentDrawenNode.image);
                          me.Globals.addNodeToHistory();
                          me.firstOpen = false;

                          me.preview = me.Globals.modeView = me.containerClosed = true;
                          if (document.getElementById('first-page')) document.getElementById('first-page').style.display = "none";
                        }, function error(e) {
                          console.log(e);
                        });

                      var zip = new JSZip();
                      zip.loadAsync(data)
                        .then(function (zip) {
                          return zip.file("params.json").async("string");
                        }).then(function success(text) {
                          me.Globals.params = JSON.parse(text);
                        }, function error(e) {
                          console.log(e);
                        });
                    },
                    error => {
                      console.log(error);
                    });
              } else {
                alert(data.message)
              }
            },
            error => {
              console.log(error);
            });

      } else {

        let project = JSON.parse(localStorage.getItem('projects')).filter(function (project) {
          return project.id == params.id;
        });

        this.project = project[0];

        if (this.project.link) {

          this.userService.getFile(this.project).pipe(first())
            .subscribe(
              data => {
                var zip = new JSZip();
                zip.loadAsync(data)
                  .then(function (zip) {
                    return zip.file("data.json").async("string");
                  }).then(function success(text) {
                    me.Globals.graph = JSON.parse(text);
                    if(me.Globals.getHomePage() !== null){
                      me.Globals.currentDrawenNode = me.Globals.getHomePage();
                    }else{
                      me.Globals.currentDrawenNode = me.Globals.graph.nodes[0];
                    }
                    me.Globals.imgURL = me.Globals.currentDrawenNode.image;
                    me.Globals.imgURL$.next(me.Globals.currentDrawenNode.image);
                    me.Globals.addNodeToHistory();
                    me.firstOpen = false;
                    if (document.getElementById('first-page')) document.getElementById('first-page').style.display = "none";
                  }, function error(e) {
                    console.log(e);
                  });

                var zip = new JSZip();
                zip.loadAsync(data)
                  .then(function (zip) {
                    return zip.file("params.json").async("string");
                  }).then(function success(text) {
                    me.Globals.params = JSON.parse(text);
                  }, function error(e) {
                    console.log(e);
                  });
              },
              error => {
                console.log(error);
              });

        }
      }

    });
  }

  calculateSearch() {
    this.showResultSearch();
    this.numPoint = 0;
    this.numPage = 0;
    this.searchPage = [];
    this.searchPoint = [];
    let nodes = this.Globals.graph.nodes;
    for (var i = 0; i < nodes.length; i++) {
      if (nodes[i].name != undefined && nodes[i].name != "" && nodes[i].name.toLowerCase().includes(this.searchText)) {
        this.numPage++;
        this.searchPage.push(nodes[i]);
      }
      let edges = this.Globals.graph.nodes[i].edges;
      for (var j = 0; j < edges.length; j++) {
        let props = edges[j].properties;
        for (var h = 0; h < props.length; h++) {
          let newPrp: any;
          if (props[h].name != undefined && props[h].name != "" && props[h].name.toLowerCase().includes(this.searchText)) {
            this.numPoint++;
            newPrp = props[h];
            newPrp.source = edges[j].source;
            this.searchPoint.push(newPrp);
          }
          if (props[h].value != undefined && props[h].value != "" && props[h].value.toLowerCase().includes(this.searchText)) {
            this.numPoint++;
            newPrp = props[h];
            newPrp.source = edges[j].source;
            this.searchPoint.push(newPrp);
          }
        }
      }
    }
  }

  clickOutside(event){
    this.hideResultSearch();
  }

  hideResultSearch() {
    this.showSearch = false;
  }

  showResultSearch() {
    this.showSearch = true;
  }

  getElementOffset(e, el) {

    let left = e.offsetX;
    let top = e.offsetY;
    let element = el;

    // Loop through the DOM tree
    // and add it's parent's offset to get page offset
    do {
      top += element.offsetY || 0;
      left += element.offsetX || 0;
      element = element.offsetParent;
    } while (element);

    return { top, left }
  }

  initialiseResize(e, idEditedEdge) {

    var edgeElement = document.getElementById(idEditedEdge);

    this.getMousePosition(e);
    this.selectEdge = idEditedEdge;
    this.startWidth = parseFloat(document.getElementById(idEditedEdge).style.width);
    this.startHeight = parseFloat(document.getElementById(idEditedEdge).style.height);
    this.resize = true;
    this.panZoomConfig.panOnClickDrag = false;

    return;
  }

  startResizing(e) {
    if (!this.resize) return;

    this.setMousePosition(e);
    let idEditedEdge = this.selectEdge;

    let width = this.startWidth + (this.mouse.x - this.mouse.startX);
    let height = this.startHeight + (this.mouse.y - this.mouse.startY);

    document.getElementById(idEditedEdge).style.width = width + 'px';
    document.getElementById(idEditedEdge).style.height = height + 'px';
  }

  stopResizing(e, idEditedEdge) {

    this.setMousePosition(e);

    this.resize = false;
    this.panZoomConfig.panOnClickDrag = true;

    let width = this.startWidth + (this.mouse.x - this.mouse.startX);
    let height = this.startHeight + (this.mouse.y - this.mouse.startY);

    let edge = this.Globals.getEdgeFromId(idEditedEdge);

    edge.shape.width = width;
    edge.shape.height = height;

    let idEdge = this.Globals.guid();


    let newEdge: any = {
      id: idEdge,
      source: this.Globals.currentDrawenNode.id,
      target: edge.target,
      shapeType: edge.shapeType,
      shape: edge.shape,
      coords: edge.coords,
      properties: edge.properties
    };

    this.Globals.removeEdge(edge);
    this.Globals.currentDrawenNode.edges.push(newEdge);
    document.getElementById("handle-" + idEditedEdge).style.opacity = "0";

  }



  initialiseDrag(e, idEditedEdge) {
    this.getMousePosition(e);
    this.selectEdge = idEditedEdge;
    this.startX = parseFloat(document.getElementById(idEditedEdge).style.left);
    this.startY = parseFloat(document.getElementById(idEditedEdge).style.top);
    this.drag = true;
    this.panZoomConfig.panOnClickDrag = false;

    return;
  }

  startDraging(e) {
    if (!this.drag) return;

    this.setMousePosition(e);
    let idEditedEdge = this.selectEdge;

    let leftEdge = this.startX + (this.mouse.x - this.mouse.startX);
    let topEdge = this.startY + (this.mouse.y - this.mouse.startY);

    document.getElementById(idEditedEdge).style.left = leftEdge + 'px';
    document.getElementById(idEditedEdge).style.top = topEdge + 'px';
  }

  stopDraging(e, idEditedEdge) {

    this.setMousePosition(e);

    this.drag = false;
    this.panZoomConfig.panOnClickDrag = true;

    let leftEdge = this.startX + (this.mouse.x - this.mouse.startX);
    let topEdge = this.startY + (this.mouse.y - this.mouse.startY);

    let edge = this.Globals.getEdgeFromId(idEditedEdge);

    let idEdge = this.Globals.guid();

    edge.shape.originX = leftEdge;
    edge.shape.originY = topEdge;


    let newEdge: any = {
      id: idEdge,
      source: this.Globals.currentDrawenNode.id,
      target: edge.target,
      shapeType: edge.shapeType,
      shape: edge.shape,
      coords: { x: leftEdge, y: topEdge },
      properties: edge.properties
    };

    this.Globals.removeEdge(edge);
    this.Globals.currentDrawenNode.edges.push(newEdge);

  }

  ngOnInit(): void {
    this.apiSubscription = this.panZoomConfig.api.subscribe((api: PanZoomAPI) => this.panZoomAPI = api);
    this.modelChangedSubscription = this.panZoomConfig.modelChanged.subscribe((model: PanZoomModel) => this.onModelChanged(model));
    this.Globals.imgURL$.subscribe((img) => this.onMainImageChanged(img));
    this.Globals.createMap();
    if (!this.Globals.params) {
      this.Globals.params = {
        borderStyle: "solid",
        borderWidth: 3,
        borderColor: "#0000ff80",
        tooltipBgColor: "#fff",
        tooltipTextColor: "#333",
        tooltipLinkColor: "#337ab7",
        showEdge: true
      }
    }
    this.pannedTo = false;

    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();

    if (!this.isDesktopDevice) this.preview = true;
    this.selectedShape = ShapeType.NoShape;
    this.shapeValue = "Move";
    var retrievedObject = localStorage.getItem('graph');
    var retrievedParams = localStorage.getItem('params');

    this.firstOpen = true;
    // if (this.preview && retrievedParams && this.isDesktopDevice) {
    //   //this.Globals.params = JSON.parse(retrievedParams);
    // }
    // if (this.preview && retrievedObject && this.isDesktopDevice) {
    //   // this.firstOpen = false;
    //   // this.Globals.graph = JSON.parse(retrievedObject);
    //   // this.Globals.currentDrawenNode = this.Globals.graph.nodes[0];
    //   // this.Globals.imgURL = this.Globals.graph.nodes[0].image;
    //   // this.Globals.imgURL$.next(this.Globals.graph.nodes[0].image);
    //   setTimeout(() => document.getElementById('reseteView').click());
    //   this.Globals.addNodeToHistory();
    // }
  }

  ngOnDestroy(): void {
    this.apiSubscription.unsubscribe();
    this.modelChangedSubscription.unsubscribe();
    this.Globals.imgURL$.unsubscribe();
    localStorage.removeItem('graph');
    localStorage.removeItem('params');
  }

  ngAfterViewInit(): void {
    var me = this;
    if (this.project && !this.project.link) {
      this.firstOpen = false;
      if (document.getElementById('first-page')) document.getElementById('first-page').style.display = "none";
    }
    //   me.Globals.graph = JSON.parse(this.project.data);
    //   me.Globals.currentDrawenNode = me.Globals.graph.nodes[0];
    //   me.Globals.imgURL = me.Globals.graph.nodes[0].image;
    //   me.Globals.imgURL$.next(me.Globals.graph.nodes[0].image);
    //   me.Globals.addNodeToHistory();
    // }
    // if (this.project.params) {
    //   me.Globals.params = JSON.parse(this.project.params);
    // }
    //if (document.getElementById('first-page')) document.getElementById('first-page').style.display = "none";

    //this.zoomLevel = 2;
    //this.changeDetector.detectChanges();
  }

  onDragDropped(ev: Event) {
    ev['source']._dragRef._previewRect = null;
  }

  showParent(e) {
    if (!this.preview || this.Globals.params.showEdge || !this.isDesktopDevice) return;
    var element = document.getElementById(e);
    element.classList.remove("opacity0");
    element.classList.add("opacity1");
  }

  hideParent(e) {
    if (!this.preview || this.Globals.params.showEdge || !this.isDesktopDevice) return;
    var element = document.getElementById(e);
    element.classList.remove("opacity1");
    element.classList.add("opacity0");
  }

  filesDropped(files: FileHandle[]): void {
    if (this.preview) return;
    this.files = files;
    let last = false;
    this.files.forEach((file, key, arr) => {
      if (key === arr.length - 1) last = true;
      this.newNode(file, last);
    });
  }

  newNode(node: any, last: boolean) {
    //this.nameNode;
    let file: any = node.file;
    let idNode = this.Globals.guid();

    let newNode = {
      id: idNode,
      name: file.name,
      image: "",
      home: false,
      edges: []
    };

    let mimeType = file.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    let reader: any = new FileReader();
    //this.imagePath = files;
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      //this.MapService.sendMessage(reader.result);
      newNode.image = reader.result;
      if (last) {
        this.Globals.imgURL = reader.result;
        this.Globals.imgURL$.next(reader.result);
      }
      //console.log(this.Globals.images);
    }
    this.Globals.graph.nodes.push(newNode);
    if (last) {
      this.Globals.currentDrawenNode = newNode;
      this.Globals.addNodeToHistory();
    }
  }

  newProject() {
    this.firstOpen = false;
    //setTimeout(() => this.openDialog(null));
  }

  importProject() {
    document.getElementById('file').click();
  }

  centerImage() {
    // Get container dimensions
    if (!this.Globals.imgURL || document.getElementById('imgZoom') == null) return;
    // var container_height = document.getElementById('containerZoom').offsetHeight;
    // var container_width = document.getElementById('containerZoom').offsetWidth;

    //this.pannedTo = true;
    // Get image dimensions
    var image_height_initial = document.getElementById('imgZoom').offsetHeight;
    var image_width_initial = document.getElementById('imgZoom').offsetWidth;

    this.panZoomConfig.initialZoomToFit = { x: 0, y: 0, width: image_width_initial, height: image_height_initial }

    // return;



    // // Calculate scaling factor
    // var zoom_factor = 1;
    // var zoom_factor_width = 1;
    // var zoom_factor_height = 1;

    // // Check to determine whether to stretch along width or height
    // if (image_height_initial > container_height) {
    //   zoom_factor_height = container_height / image_height_initial;
    // }
    // if (image_width_initial > container_width) {
    //   zoom_factor_width = container_width / image_width_initial;
    // }

    // if (zoom_factor_height > zoom_factor_width) {
    //   zoom_factor = zoom_factor_width;
    // } else if (zoom_factor_width > zoom_factor_height) {
    //   zoom_factor = zoom_factor_height;
    // }

    // console.log(zoom_factor);

    // // Calculate new image dimensions after zoom
    // var image_width = image_width_initial * zoom_factor;
    // var image_height = image_height_initial * zoom_factor;

    // // Calculate the center of image since origin is at x:50% y:50%
    // var image_center_left = image_width / 2;
    // var image_center_top = image_height / 2;


    // // Calculate offset of the image after zoom
    // var image_offset_left = image_center_left - (image_width / 2.0);
    // var image_offset_top = image_center_top - (image_height / 2.0);

    // // Calculate desired offset for image
    // var new_offset_left = (container_width - image_width) / 2.0;
    // var new_offset_top = (container_height - image_height) / 2.0;

    // //document.getElementById('imgZoom').style.width = image_width + "px";
    // //document.getElementById('imgZoom').style.height = image_height + "px";

    // console.log(image_height, image_width);

    // // Pan to set desired offset for image
    // var pan_left = new_offset_left - image_offset_left;
    // var pan_top = new_offset_top - image_offset_top;
    // var me = this;
    // this.panZoomConfig.initialZoomToFit = { x: 0, y: 0, width: image_width_initial, height: image_height_initial }
    // // setTimeout(function () {
    // //   me.panZoomConfig.initialPanX = pan_left;
    // //   me.panZoomConfig.initialPanY = pan_top;
    // //   if(zoom_factor < 1 ) me.panZoomConfig.initialZoomLevel = 1 + zoom_factor;
    // // });
    // //this.panZoomAPI.zoomToFit({ x: 0, y: 0, width: image_width_initial, height: image_height_initial });

    // if (tentative == 1) {
    //   setTimeout(() => this.centerImage(2));
    //   this.pannedTo = true;
    // }


  }

  selectShape(shapeType: string): void {
    if (shapeType == "Move") {
      this.selectedShape = ShapeType.NoShape;
      this.shapeValue = "Move";
      this.panZoomConfig.panOnClickDrag = true;
    } else {
      this.selectedShape = ShapeType[shapeType];
      this.shapeValue = ShapeType[this.selectedShape];
      this.isSelectingPoints = false;
      this.panZoomConfig.panOnClickDrag = false;
    }
  }

  getShapes(): ShapeComponent[] {
    return this.shapeService.getShapeComponents();
  }

  deSelectComponents() {
    var shapes = this.getShapes();
    for (var i = 0; i < shapes.length; i++) {
      shapes[i].isSelected = false;
    }
  }

  canSelectPoints(): boolean {
    if (this.selectedShape == ShapeType.PolyLine || this.selectedShape == ShapeType.Path) {
      return true;
    }
    return false;
  }

  private buildComponent(shapeType: ShapeType): any {
    switch (shapeType) {
      case ShapeType.Circle:
        return CircleComponent;
      case ShapeType.Rectangle:
        return RectangleComponent;
    }
    return null;
  }


  onMouseDown(event): void {
    this.getMousePosition(event);
    this.Globals.appendCss();

    this.deSelectComponents();
    if (this.selectedShape != ShapeType.NoShape && !this.isSelectingPoints) {
      let injector = Injector.create([], this.viewContainerRef.parentInjector);
      let factory = this.componentFactoryResolver.resolveComponentFactory(this.buildComponent(this.selectedShape));
      let component = factory.create(injector);
      this.selectedComponent = <ShapeComponent>component.instance;
      this.shapeService.setShapeComponent(this.selectedComponent);

      // console.log('create component ', this.selectedShape);
      // console.log('component : ', this.selectedComponent);
      this.shapeProperties = new ShapeProperties();
      this.shapeProperties.name = this.selectedComponent.shape.shapeProperties.name;
      this.selectedComponent.shape.shapeProperties = Object.assign({}, this.shapeProperties);

      if (this.canSelectPoints()) {
        this.isSelectingPoints = true;
      } else {
        this.isDrawing = true;
        this.selectedComponent.startDrawing(this.mouse);
      }
    }
  }

  onMouseMove(event): void {
    if (this.resize) {
      this.startResizing(event);
      return
    }
    this.setMousePosition(event);
    if (this.selectedComponent && (this.isDrawing || this.isSelectingPoints)) {
      this.selectedComponent.draw(this.mouse);
    }
  }

  onMouseUp(event): void {
    this.getMousePosition(event);

    if (this.isDrawing) {
      let offsetX = this.selectedComponent.shape.originX;
      let offsetY = this.selectedComponent.shape.originY;

      let idEdge = this.Globals.guid();
      let targetNodeId = "";

      let newEdge: any = {
        id: idEdge,
        shapeType: this.shapeValue,
        shape: this.selectedComponent.shape,
        source: this.Globals.currentDrawenNode.id,
        target: targetNodeId,
        coords: { x: offsetX, y: offsetY },
        properties: []
      };

      this.Globals.currentDrawenNode.edges.push(newEdge);
      this.openDialogEdge(idEdge, this.selectedComponent.shape, this.shapeValue);
      this.clearShapes();
    }
    //this.selectedShape = ShapeType.NoShape;
    //this.shapeValue = ShapeType[this.selectedShape];
    this.isDrawing = false;
    this.isDragging = false;
    this.isResizing = false;
  }

  clearShapes(): void {
    this.shapeService.removeAllShapeComponents();
    //this.selectedShape = ShapeType.NoShape;
    //this.shapeValue = ShapeType[this.selectedShape];
  }

  setMousePosition(e) {
    let widthN = e.clientX - (250 + this.getMatrix(document.getElementsByClassName('panElement')[0]).x);
    let heightN = e.clientY - (50 + this.getMatrix(document.getElementsByClassName('panElement')[0]).y);


    let image_offset_height = document.getElementById('imgZoom').offsetHeight;
    let image_height = document.getElementById('imgZoom').getBoundingClientRect().height;
    let scal = image_offset_height / image_height;

    this.mouse.x = widthN * scal;
    this.mouse.y = heightN * scal;

    return;

    var ev = e || window.event; //Moz || IE
    var transform = e.srcElement.offsetParent.offsetParent.style.transform.replace('translate3d(', '').split(',');
    console.log(ev.pageX, parseInt(transform[0]));
    if (ev.pageX) { //Moz
      this.mouse.x = ev.pageX - parseInt(transform[0]) - 250;
      this.mouse.y = ev.pageY - parseInt(transform[1]);
    } else if (ev.clientX) { //IE
      this.mouse.x = ev.clientX + document.body.scrollLeft;
      this.mouse.y = ev.clientY + document.body.scrollTop;
    }
  }

  getMousePosition(e: MouseEvent) {

    let widthN = e.clientX - (250 + this.getMatrix(document.getElementsByClassName('panElement')[0]).x);
    let heightN = e.clientY - (50 + this.getMatrix(document.getElementsByClassName('panElement')[0]).y);


    let image_offset_height = document.getElementById('imgZoom').offsetHeight;
    let image_height = document.getElementById('imgZoom').getBoundingClientRect().height;
    let scal = image_offset_height / image_height;

    this.mouse.startX = widthN * scal;
    this.mouse.startY = heightN * scal;
  }

  openDialogParams() {
    const dialogRefParam = this.dialog.open(DialogParamsComponent, {
      width: '250px',
      height: 'calc( 100% - 50px )',
      position: { right: '0', top: '50px' },
      data: { project: this.project }
    });
    return false;
  }

  openDialogParamsSec(){
    let permission = "";
    if (this.project.permission) permission = this.project.permission.permission;
    const dialogRefParamSec = this.dialog.open(DialogParamsSecComponent, {
      width: '250px',
      height: 'calc( 100% - 50px )',
      position: { right: '0', top: '50px' },
      data: { project: this.project, permission: permission }
    });
    return false;
  }

  openDialog(Node) {
    let nameNode = "";
    if (Node) nameNode = Node.name;
    const dialogRef = this.dialog.open(DialogMapComponent, {
      width: '350px',
      data: { Node: Node, nameNode: nameNode }
    });
    this.pannedTo = false;
    return false;
  }

  openDialogEdge(idEditedEdge, selectedShape, shapeValue) {
    const dialogRefEdge = this.dialog.open(DialogEdgeComponent, {
      width: '350px',
      data: { selectedShape: selectedShape, shapeValue: shapeValue, idEditedEdge: idEditedEdge, nodes: this.Globals.graph.nodes }
    });
    return false;
  }

  openDialogView() {
    if (this.dialog.openDialogs.length > 0 && this.dialog.openDialogs[0].componentInstance.data && this.dialog.openDialogs[0].componentInstance.data.isMap) {
      this.dialog.openDialogs[0].close();
      return false;
    }
    const dialogRefView = this.dialog.open(DialogViewComponent, {
      width: '20%',
      height: '80%',
      minHeight: '200px',
      minWidth: '200px',
      hasBackdrop: false,
      position: { left: '0', top: '50px' },
      data: { isMap: true },
      panelClass: "view-panel"
    });
    return false;
  }

  editEdge(event, idEditedEdge) {
    this.openDialogEdge(idEditedEdge, null, null);
    return false;
  }

  resizeEdge(e, idEditedEdge) {
    this.showProperties(e, idEditedEdge);
    document.getElementById("handle-" + idEditedEdge).style.opacity = "1";
    return false;
  }

  showProperties(e, idEditedEdge) {
    if (!document.getElementById("tooltip-" + idEditedEdge)) return;

    this.hideTooltip = false;
    let image_offset_height = document.getElementById('imgZoom').offsetHeight;
    let image_height = document.getElementById('imgZoom').getBoundingClientRect().height;
    let scal = image_offset_height / image_height;
    let widthTooltip = document.getElementById("tooltip-" + idEditedEdge).offsetWidth;
    let widthEdge = document.getElementById(idEditedEdge).offsetWidth;
    let leftTooltip = (widthEdge - widthTooltip) / 2 + "px";

    document.getElementById("tooltip-" + idEditedEdge).style.transform = "scale(" + scal + ")";
    document.getElementById("tooltip-" + idEditedEdge).style.opacity = "1";
    document.getElementById("tooltip-" + idEditedEdge).style.left = leftTooltip;

    let heightTooltip = document.getElementById("tooltip-" + idEditedEdge).offsetHeight;
    document.getElementById("tooltip-" + idEditedEdge).style.top = -heightTooltip + "px";

    return false;
  }

  showMobileProperties(e, idEditedEdge) {

    if (document.getElementById("tooltipMobile-" + idEditedEdge) && this.Globals.getEdgeFromId(idEditedEdge).target != "") {
      this.goTarget(this.Globals.getEdgeFromId(idEditedEdge).target);
      this.hideAllMobilePrperties();
      return;
    }
    this.hideAllMobilePrperties();
    if (!document.getElementById("tooltip-" + idEditedEdge)) return;
    let props = document.getElementById("tooltip-" + idEditedEdge).lastChild;
    let styleContainer = document.getElementById("tooltip-" + idEditedEdge).style.cssText;
    let cln = props.cloneNode(true);

    let container = document.createElement("DIV");
    container.id = "tooltipMobile-" + idEditedEdge
    container.appendChild(cln);
    container.classList.add("tooltip", "showTooltip", "showMobileTooltip");
    container.style.cssText = styleContainer;
    document.body.appendChild(container);

    return false;
  }

  hideAllMobilePrperties() {
    let elements = document.getElementsByClassName('showMobileTooltip');
    for (let i = 0; i < elements.length; i++) {
      elements[i].remove();
    }
  }

  unresizeEdge(e, idEditedEdge) {
    this.hideProperties(e, idEditedEdge);
    document.getElementById("handle-" + idEditedEdge).style.opacity = "0";
    return false;
  }

  hideProperties(e, idEditedEdge) {
    if (!document.getElementById("tooltip-" + idEditedEdge)) return;
    var me = this;
    this.hideTooltip = true;
    //setTimeout(function () {
    me.hidePropertiesAfter(e, idEditedEdge);
    //}, 10);
    return false;
  }

  hidePropertiesAfter(e, idEditedEdge) {
    if (!document.getElementById("tooltip-" + idEditedEdge) || !this.hideTooltip) return;

    document.getElementById("tooltip-" + idEditedEdge).style.opacity = "0";
    document.getElementById("tooltip-" + idEditedEdge).style.transform = "scale(0)";
    return false;
  }

  displayData(data, type) {
    let Node = null;
    if (type == 'node') {
      if (!data) {
        this.Globals.imgURL = ""
        this.Globals.imgURL$.next("");
        return false;
      }
      Node = data;
    } else {
      Node = this.Globals.getNodeFromId(data.source);
    }
    this.Globals.currentDrawenNode = Node;
    this.Globals.imgURL = Node.image;
    this.Globals.imgURL$.next(Node.image);
    this.Globals.addNodeToHistory();
    setTimeout(() => this.centerImage());
    this.hideResultSearch();
    this.searchText = "";
    return false;
  }

  editNode(Node) {
    this.openDialog(Node);
    return false;
  }

  makeItHome(Node) {
    this.Globals.makeItHome(Node);
    return false;
  }

  deleteNode(Node) {
    if (confirm('Vous êtes sur?')) {
      this.Globals.removeNode(Node);
      this.displayData(this.Globals.graph.nodes[0], "node");
    }
    return false;
  }

  goTarget(idNode, fromHistory = false) {
    if (idNode == "") return false;
    let Node = this.Globals.getNodeFromId(idNode);
    if (!Node) return false;
    this.Globals.currentDrawenNode = Node;
    this.Globals.imgURL = Node.image;
    this.Globals.imgURL$.next(Node.image);
    this.pannedTo = false;
    setTimeout(() => this.centerImage());
    if (!fromHistory) this.Globals.addNodeToHistory();
    return false;
  }

  chooseEdge(event) {
    let x = event.offsetX;
    let y = event.offsetY;

    this.openDialogEdge(x, y, "");
    return false;
  }

  switchMode() {
    localStorage.setItem('graph', JSON.stringify(this.Globals.graph));
    localStorage.setItem('params', JSON.stringify(this.Globals.params));

    window.open('/preview/' + this.project.id);
    return false;
  }

  fileChangeEvent = function (fileInput: any) {
    this.hideAllMobilePrperties();
    this.firstOpen = false;
    let me = this;
    if (fileInput.target.files && fileInput.target.files[0]) {
      var zip = new JSZip();
      zip.loadAsync(fileInput.target.files[0])
        .then(function (zip) {
          return zip.file("data.json").async("string");
        }).then(function success(text) {
          me.Globals.graph = JSON.parse(text);
          me.Globals.currentDrawenNode = me.Globals.graph.nodes[0];
          me.Globals.imgURL = me.Globals.graph.nodes[0].image;
          me.Globals.imgURL$.next(me.Globals.graph.nodes[0].image);
          me.Globals.addNodeToHistory();
          if (document.getElementById('first-page')) document.getElementById('first-page').style.display = "none";
        }, function error(e) {
          console.log(e);
        });
    }
    if (fileInput.target.files && fileInput.target.files[0]) {
      var zip = new JSZip();
      zip.loadAsync(fileInput.target.files[0])
        .then(function (zip) {
          return zip.file("params.json").async("string");
        }).then(function success(text) {
          me.Globals.params = JSON.parse(text);
        }, function error(e) {
          console.log(e);
        });
    }
  }

  saveToServer = function (content) {

    this.loadSaveProject = true;

    const formData = new FormData();
    formData.append('file', content);
    this.project.media = this.Globals.graph.nodes[0].image;
    formData.append('project', JSON.stringify(this.project));
    formData.append('action', 'saveProject');

    this.userSer.saveProject(formData).pipe(first())
      .subscribe(
        data => {
          this.Globals.updateProject(data);
          this.project = data;
          this.loadSaveProject = false;
        },
        error => {
          console.log(error);
        });

  }

  saveTo = function () {

    const me = this;
    const jszip = new JSZip();
    jszip.file('data.json', JSON.stringify(this.Globals.graph));
    jszip.file('params.json', JSON.stringify(this.Globals.params));


    jszip.generateAsync({ type: 'blob' }).then(function (content) {
      me.saveToServer(content)
    });
    return false;

  };

  zoomin() {
    this.panZoomAPI.zoomIn();
  }



  zoomout() {
    this.panZoomAPI.zoomOut();
  }

  resetView() {
    this.centerImage();
    this.panZoomAPI.resetView();
  }

  changeZoom(value, isChange) {
    this.changeZoomLevel = isChange;
    this.panZoomAPI.changeZoomLevel(value);
  }

  onMainImageChanged(img) {
    if (img == "") this.Globals.imgURL = this.Globals.defaultImg;
    //console.log("onMainImageChanged");
    if (img != this.Globals.defaultImg) this.changeDetector.detectChanges();
    if (document.getElementById('reseteView')) setTimeout(() => document.getElementById('reseteView').click());
  }

  getMatrix(element: any) {
    if (!element.style.transform) return;
    const values = element.style.transform.split(/\w+\(|\);?/);
    const transform = values[1].split(/,\s?/g).map(parseFloat);

    return {
      x: transform[0],
      y: transform[1],
      z: transform[2]
    };
  }

  onModelChanged(model: PanZoomModel): void {

    if (isNaN(model.pan.x) || model.pan.x == Infinity || model.pan.x == -Infinity) model.pan.x = this.getMatrix(document.getElementsByClassName('panElement')[0]).x;
    if (isNaN(model.pan.y) || model.pan.y == Infinity || model.pan.y == -Infinity) model.pan.y = this.getMatrix(document.getElementsByClassName('panElement')[0]).y;

    var limitEspace = 100;
    var container_height = document.getElementById('containerZoom').offsetHeight - limitEspace;
    var container_width = document.getElementById('containerZoom').offsetWidth - limitEspace;
    //console.log(container_width, container_height);
    // Get image dimensions
    var image_height = document.getElementById('imgZoom').getBoundingClientRect().height;
    var image_width = document.getElementById('imgZoom').getBoundingClientRect().width;

    if (image_width < limitEspace || image_height < limitEspace) limitEspace = 0;

    // if (!model.isPanning &&
    //   (model.pan.x > container_width ||
    //     model.pan.y > container_height ||
    //     model.pan.x < ((image_width - limitEspace) * -1) ||
    //     model.pan.y < ((image_height - limitEspace) * -1))) {
    //   setTimeout(() => document.getElementById('reseteView').click());
    //   //this.panToPoint( { x: image_width_initial, y: image_height_initial } );
    // }
    //console.log(this.changeZoomLevel)
    if (!this.changeZoomLevel) return;
    this.zoomLevel = model.zoomLevel;
    this.panzoomModel = JSON.parse(JSON.stringify(model));

    if (this.Globals.imgURL != this.Globals.defaultImg) {
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    }
  }

  onDragEnded(event, Edge) {
    const transform = event.source.element.nativeElement.style.transform.replace('translate3d(', '').split(',');
    //console.log(parseInt(transform[0]),parseInt(transform[1]));return;
    // let regex = /translate3d\(\s?(?<x>[-]?\d*)px,\s?(?<y>[-]?\d*)px,\s?(?<z>[-]?\d*)px\)/;
    // var values = regex.exec(transform);
    let offset = { x: parseInt(transform[0]), y: parseInt(transform[1]) }

    let ofssetEdge = {
      positionX: event.source.element.nativeElement.offsetLeft + offset.x,
      positionY: event.source.element.nativeElement.offsetTop + offset.y
    }

    this.editPositionEdge(ofssetEdge, Edge);
  }

  editPositionEdge(ofssetEdge, Edge) {

    let edge = Edge;

    let offsetX = ofssetEdge.positionX;
    let offsetY = ofssetEdge.positionY;

    let idEdge = this.Globals.guid();
    edge.shape.originX = offsetX;
    edge.shape.originY = offsetY;

    let newEdge: any = {
      id: idEdge,
      source: Edge.source,
      target: Edge.target,
      shapeType: edge.shapeType,
      shape: edge.shape,
      coords: { x: offsetX, y: offsetY },
      properties: Edge.properties
    };
    this.Globals.removeEdge(edge);
    this.Globals.currentDrawenNode.edges.push(newEdge);
  };

  next() {
    if (this.Globals.currentIdInHistory > this.Globals.historyLocal.length - 1) {
      return false;
    }
    this.Globals.currentIdInHistory = this.Globals.currentIdInHistory + 1;
    this.goTarget(this.Globals.historyLocal[this.Globals.currentIdInHistory], true);
    return false
  };

  previous() {
    if (this.Globals.currentIdInHistory == 0) {
      return false;
    }
    this.Globals.currentIdInHistory = this.Globals.currentIdInHistory - 1;
    this.goTarget(this.Globals.historyLocal[this.Globals.currentIdInHistory], true);
    return false
  };

}

@Component({
  selector: 'dialog-map',
  templateUrl: './dialog-map-component.html',
  styleUrls: ['./dialog.component.css']
})

export class DialogMapComponent implements OnInit {


  nameNode: string;
  private imageNode: string;
  private idNode: string;
  private idImage: string;
  private images: Array<string> = [];
  private newNode: any;
  Globals: Globals;

  constructor(public dialogRef: MatDialogRef<DialogMapComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, Globals: Globals) {
    this.Globals = Globals;
  }

  ngOnInit() {
    // this.graph = this.AppService.initGraph();
    // this.AppService.getGraph().subscribe(graph => {
    //   this.graph = graph;
    //   console.log(graph);
    // });
    // this.AppService.getImages().subscribe(images => {
    //   this.images = images;
    //   console.log(images);
    // });
  }

  editNode(Node) {
    Node.name = this.data.nameNode;
    if (this.imageNode) {
      let files: any = this.imageNode;
      let mimeType = files[0].type;
      if (mimeType.match(/image\/*/) == null) {
        return;
      }

      let reader: any = new FileReader();
      //this.imagePath = files;
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
        //this.MapService.sendMessage(reader.result);
        Node.image = reader.result;
        this.Globals.imgURL = reader.result;
        this.Globals.imgURL$.next(reader.result);
        //console.log(this.Globals.images);
      }
    }
    this.Globals.currentDrawenNode = Node;
    this.dialogRef.close();
  }

  addNode() {

    //this.nameNode;
    this.idNode = this.Globals.guid();
    //console.log(this.idNode)
    this.idImage = this.Globals.guid();
    //console.log(this.image)
    //this.images.push(this.idImage);
    //console.log(this.images)
    let me = this;

    this.newNode = {
      id: me.idNode,
      name: me.data.nameNode,
      image: "",
      edges: []
    };
    //console.log(this.newNode)

    //console.log(this.Globals)
    //this.AppService.editGraph(this.graph);
    //console.log(this.Globals.graph);
    // $("#anDialog").dialog('close');
    // addNodeToAdmin(newNode);
    //console.log(this.imageNode)

    //this.Globals.loadAndDisplayImage(this.idNode, this.idImage, this.imageNode);
    let files: any = this.imageNode;
    let mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    let reader: any = new FileReader();
    //this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      //this.MapService.sendMessage(reader.result);
      this.newNode.image = reader.result;
      this.Globals.imgURL = reader.result;
      this.Globals.imgURL$.next(reader.result);
      //console.log(this.Globals.images);
    }
    this.Globals.graph.nodes.push(this.newNode);
    this.Globals.currentDrawenNode = this.newNode;
    this.Globals.addNodeToHistory();
    this.dialogRef.close();
  }

  preview(files) {
    if (files.length === 0)
      return;
    this.imageNode = files;
  }

}

@Component({
  selector: 'dialog-params',
  templateUrl: './dialog-params-component.html',
  styleUrls: ['./dialog.component.css']
})

export class DialogParamsComponent {
  Globals: Globals;

  constructor(public dialogRef: MatDialogRef<DialogParamsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    Globals: Globals,
    private userService: UserService,) {
    this.Globals = Globals;
  }


}

@Component({
  selector: 'dialog-params',
  templateUrl: './dialog-params-sec-component.html',
  styleUrls: ['./dialog.component.css']
})

export class DialogParamsSecComponent {
  Globals: Globals;

  constructor(public dialogRef: MatDialogRef<DialogParamsSecComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    Globals: Globals,
    private userService: UserService,) {
    this.Globals = Globals;
  }

  changePermission(event) {
    console.log(this.data);
    console.log(event);


    const formData = new FormData();
    formData.append('project', JSON.stringify(this.data.project));
    formData.append('permission', event.value);
    formData.append('action', "editPermission");


    this.userService.editPermission(formData).pipe(first())
      .subscribe(
        data => {
          this.Globals.updateProject(data);
        },
        error => {
          console.log(error);
        });


  }

}

export interface Grid {
  ID: string;
  name: string;
  value: string;
}

@Component({
  selector: 'dialog-edge',
  templateUrl: './dialog-edge-component.html',
  styleUrls: ['./dialog.component.css']
})

export class DialogEdgeComponent implements OnInit {
  grid: Array<Grid> = [];
  name: string;
  value: string;
  listNodeForCnfneSelect: string = "";
  Globals: Globals;

  constructor(public dialogRefEdge: MatDialogRef<DialogEdgeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, Globals: Globals) {
    this.Globals = Globals;
  }

  ngOnInit() {
    //this.refreshDropDownNode(null);
    //this.refreshGrid(null);
    if (this.data.idEditedEdge != "") {
      this.grid = this.Globals.getEdgeFromId(this.data.idEditedEdge).properties;
      this.listNodeForCnfneSelect = this.Globals.getEdgeFromId(this.data.idEditedEdge).target;
    }
  }

  addPropertie() {
    let id = this.Globals.guid();
    this.grid.push({ 'ID': id, 'name': this.name, 'value': this.value });
    this.name = '';
    this.value = '';
  }

  addEdge() {
    let editedEdgeId = this.data.idEditedEdge;

    let targetNodeId = this.listNodeForCnfneSelect;
    let props = this.grid;


    // let initNode = this.Globals.getNodeFromId(this.Globals.currentDrawenNode);
    // const indexNode: number = this.Globals.graph.nodes.indexOf(initNode);
    if (editedEdgeId == "") {
      let offsetX = this.data.selectedShape.originX;
      let offsetY = this.data.selectedShape.originY;

      let idEdge = this.Globals.guid();

      let newEdge: any = {
        id: idEdge,
        shapeType: this.data.shapeValue,
        shape: this.data.selectedShape,
        source: this.Globals.currentDrawenNode.id,
        target: targetNodeId,
        coords: { x: offsetX, y: offsetY },
        properties: props
      };

      this.Globals.currentDrawenNode.edges.push(newEdge);
    } else {
      let edge = this.Globals.getEdgeFromId(editedEdgeId);

      let offsetX = edge.coords.x;
      let offsetY = edge.coords.y;

      let idEdge = this.Globals.guid();


      let newEdge: any = {
        id: idEdge,
        source: this.Globals.currentDrawenNode.id,
        target: targetNodeId,
        shapeType: edge.shapeType,
        shape: edge.shape,
        coords: { x: offsetX, y: offsetY },
        properties: props
      };

      this.Globals.removeEdge(edge);
      this.Globals.currentDrawenNode.edges.push(newEdge);
    }

    this.dialogRefEdge.close();
    //$( "#listNodeForCnfne" ).text("");

    //a faire : draw(currentDrawenNode.id);
  };

  deleteEdge() {
    let editedEdgeId = this.data.idEditedEdge;


    // let initNode = this.Globals.getNodeFromId(this.Globals.currentDrawenNode);
    // const indexNode: number = this.Globals.graph.nodes.indexOf(initNode);

    if (editedEdgeId != "") {
      let edge = this.Globals.getEdgeFromId(editedEdgeId);
      this.Globals.removeEdge(edge);

      /*const index: number = this.Globals.currentDrawenNode.edges.indexOf(edge);
      this.Globals.currentDrawenNode.edges[index] = edge;*/
    }

    this.dialogRefEdge.close();
    //$( "#listNodeForCnfne" ).text("");

    //a faire : draw(currentDrawenNode.id);
  };

  deletePropertie(propertie) {
    if (confirm('Vous êtes sur?')) {
      const index: number = this.grid.indexOf(propertie);
      if (index !== -1) {
        this.grid.splice(index, 1);
        let edge = this.Globals.getEdgeFromId(this.data.idEditedEdge);
        edge.properties = this.grid;
      }
    };
    return false;
  }

  closeThis() {
    this.dialogRefEdge.close();
  }

}

@Component({
  selector: 'dialog-view',
  templateUrl: './dialog-view-component.html',
  styleUrls: ['./dialog.component.css']
})

export class DialogViewComponent implements OnInit {
  public network: any;
  Globals: Globals;

  constructor(public dialogRefView: MatDialogRef<DialogViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private element: ElementRef, Globals: Globals) {
    this.Globals = Globals;
  }



  ngOnInit() {
    var treeData = this.getTreeData();
    this.loadVisTree(treeData);
  }

  loadVisTree(treedata) {
    var options = {};
    var container = document.getElementById("mynetwork");

    this.network = new vis.Network(container, treedata, options);

    var that = this;
    this.network.on("click", function (params) {
      that.goTarget(params.nodes[0]);
    });
  }

  goTarget(idNode) {
    if (idNode == "") return false;
    let Node = this.Globals.getNodeFromId(idNode);
    if (!Node) return false;
    this.Globals.currentDrawenNode = Node;
    this.Globals.imgURL = Node.image;
    this.Globals.imgURL$.next(Node.image);
    this.Globals.addNodeToHistory();
  }

  onNoClick() {
    this.dialogRefView.close();
  }

  getTreeData() {
    var nodes = new vis.DataSet(this.Globals.getNodesFromGraph());

    // create an array with edges
    var edges = new vis.DataSet(this.Globals.getEdgesFromGraph());

    var treeData = {
      nodes: nodes,
      edges: edges
    };
    return treeData;
  }

}
