import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], searchText: string, type: string): any[] {
        if (!items) return [];
        if (!searchText) return items;
        searchText = searchText.toLowerCase();
        return items.filter(it => {
            let val = "";
            if(it.value) val = it.value;
            if(type == "node") return it.name.toLowerCase().includes(searchText);
            if(it.name != undefined && it.name != "" && it.name.toLowerCase().includes(searchText)) return it.name.toLowerCase().includes(searchText);
            if(val != undefined && val != "" && val.toLowerCase().includes(searchText)) return val.toLowerCase().includes(searchText);
        });
    }
}